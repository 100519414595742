@import "/node_modules/toastify-js/src/toastify.css";
@import "~@fortawesome/fontawesome-free/scss/fontawesome";
@import "~@fortawesome/fontawesome-free/scss/regular";
@import "~@fortawesome/fontawesome-free/scss/solid";
@import "~@fortawesome/fontawesome-free/scss/brands";


// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';
body, html{
    background-color: #fff;
    overflow-x: hidden;
    width: 100%;
    position: relative;
    //enable toch actions
    -webkit-overflow-scrolling: touch;
    touch-action: auto;
    //prevent overflow x
    overflow-x: hidden;
    //prevent scroll bar
    scrollbar-width: none;
    -ms-overflow-style: none;
    //prevent scroll bar
    scroll-behavior: auto !important;
    &::-webkit-scrollbar {
        display: none;
    }
}
textarea:focus, 
textarea.form-control:focus, 
input.form-control:focus, 
input[type=text]:focus, 
input[type=password]:focus, 
input[type=email]:focus, 
input[type=number]:focus, 
[type=text].form-control:focus, 
[type=password].form-control:focus, 
[type=email].form-control:focus, 
[type=tel].form-control:focus, 
[contenteditable].form-control:focus {
  box-shadow: inset 0 -1px 0 #ddd;
}
input:hover {
    border-color: none !important;
    box-shadow: none !important;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
.scrollable-transparent::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 1px rgba(0, 0, 0, 0);
    background-color: #ffffff;
  }
  
  .scrollable-transparent::-webkit-scrollbar {
    width: 1px;
    height: 0px;
    background-color: #ffffff;
  }
  
  .scrollable-transparent::-webkit-scrollbar-thumb {
    background-color: #ffffff;
}
/* scroll-bar width */
::-webkit-scrollbar,
.scrollable::-webkit-scrollbar {
    width: 4px;
}
.scrollable, .scrollable-transparent{
    overflow-y: scroll;
}
/* scroll-bar Track */
::-webkit-scrollbar-track,
.scrollable::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* scroll-bar Handle */
::-webkit-scrollbar-thumb,
.scrollable::-webkit-scrollbar-thumb {
    background: #888;
}

/* scroll-bar Handle on hover */
::-webkit-scrollbar-thumb:hover,
.scrollable::-webkit-scrollbar-thumb:hover {
    background: #c0c0c0;
}
#layout-app{
    min-height: 100vh;
}
#ridder-footer{
    background-color: rgb(0,5,123);
    width: 100%;
    display: flex;
    justify-content: center;
    #ridder-feet{
        background: rgb(0,5,123);
        color: #ffffff;
        padding: 5px 0;
        text-decoration: none;
        cursor: pointer;
        transition: .3s;
        font-size: 16px;
        font-weight: 500;
        &:hover,
        &:focus{
            background-color: #CCC;
        }
    }
    #ridder-footer-logo {
        width: 2%;
        margin-left: 0.5vw;
    }
}

.btn{
    border-radius: 0% !important;
}
.btn-primary{
    color: $white;
    &:hover,
    &:focus{
        background-color: $secondary;
        border: 1px solid $secondary;
    }
}
.btn-success{
    color: $white;
}
.input-group{
    border-radius: 2px;
    &.password-input{
        input{
            border-right: none;
        }
        .input-group-append{
            border-radius: 2px;
            border: 1px solid #ced4da;
            font-size: 1.2em;
            color: #444;
    
        }
    }
    
}
i{
    cursor: pointer;
    &:hover,
    &:focus{
        color: $secondary;
    }
}
.general-padding {
    width: 100%;
    padding: 0 3vw;
}
.general-body-padding{
    width: 100%;
    padding: 0 10vw;
}
.custom-space {
    letter-spacing: 1.68px;
}
.image-input-list{
    margin: 1vh 0 0 0;
    padding: 0;
    list-style: none;
    .image-input-list-item{
        margin: 1vh 5px;
        display: inline-block;
        vertical-align: middle;
        width: min-content;
        
    }
}

.image-container{
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    border: 1px dotted $primary;
    border-radius: 3px;
    cursor: pointer;
    transition: .3s;
    &:hover,
    &:focus,
    &:hover .image-title,
    &:focus .image-title{
        color: $secondary;
    }
}

.image-title{
    font-size: .9em;
    text-align: center;
    transition: .3s;
}
.image-input{
    display: none;
}
.scrollable-transparent::-webkit-scrollbar-track
{
    -webkit-box-shadow: inset 0 0 1px rgba(0,0,0,0);
    background-color: #ffffff;
}

.scrollable-transparent::-webkit-scrollbar
{
    width: 1px;
    height: 0px;
    background-color: #ffffff;
}

.scrollable-transparent::-webkit-scrollbar-thumb
{
    background-color: #ffffff;
}
.Scrollable, .Scrollable2, .scrollable-transparent{
    overflow-y: scroll;
    overflow-x: hidden;
  }
  .fa{
    transition: .3s;
    &:hover{
        transform: scale(1.05);
    }
  }
  .pagination{
    li{
        cursor: pointer;
    }
  }
//center div
.centered{
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.gm-style .gmnoprint {
    display: none;
}
.toastify {
    font-weight: 600;
}

@media screen and (max-width: 768px){
    #ridder-footer{
        background-color: rgb(0,5,123);
        width: 100%;
        display: flex;
        justify-content: center;
        #ridder-feet{
            background: rgb(0,5,123);
            color: #ffffff;
            padding: 5px 0;
            text-decoration: none;
            cursor: pointer;
            transition: .3s;
            font-size: 16px;
            font-weight: 500;
            &:hover,
            &:focus{
                background-color: #CCC;
            }
        }
        #ridder-footer-logo {
            width: 8%;
            margin-left: 0.5vw;
        }
    }
}